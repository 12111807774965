import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux"
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import XLSX from 'xlsx';

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { PGET, GET } from 'helpers/api'
import { formatMoney, returnSign, formatDateBackend, findFromArrayById, formatDate, formatDateWithTime } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()

	const reduxAccount = useSelector(state => state.account)

	const date = new Date()
	// const paymentTypes = [
	// 	{
	// 		"id": 1,
	// 		"name": "Продажа"
	// 	},
	// 	{
	// 		"id": 3,
	// 		"name": "Возврат товаров"
	// 	},
	// 	{
	// 		"id": 4,
	// 		"name": "Долг"
	// 	},
	// 	{
	// 		"id": 5,
	// 		"name": "Погашение задолженности"
	// 	},
	// 	{
	// 		"id": 6,
	// 		"name": "Для собственных нужд"
	// 	},
	// 	{
	// 		"id": 7,
	// 		"name": "Для нужд торговой точки"
	// 	},
	// 	{
	// 		"id": 8,
	// 		"name": "Прочие"
	// 	},
	// ]

	const [poses, setPoses] = useState([])
	const [paymentTypes, setPaymentTypes] = useState([])
	const [cashiers, setCashiers] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [totalAmount, setTotalAmont] = useState([])
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		startDate: formatDateBackend(new Date(date.getFullYear(), date.getMonth(), 1)),
		endDate: formatDateBackend(new Date()),
		posId: reduxAccount.posId,
		cashierId: null,
		clientId: null,
		paymentPurposeId: null,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/services/web/api/report-expense',
		response: null
	})
	const [data, setData] = useState([])

	async function searchWithFilter(exportToExcel = false, tableSort = "") {
		var filterUrl = "/services/web/api/report-expense"
		var urlParams = ""
		checkFilter()

		if (filterData.posId)
			urlParams += returnSign(urlParams) + 'posId=' + filterData.posId
		if (filterData.cashierId)
			urlParams += returnSign(urlParams) + 'cashierId=' + filterData.cashierId
		if (filterData.paymentPurposeId)
			urlParams += returnSign(urlParams) + 'paymentPurposeId=' + filterData.paymentPurposeId
		if (filterData.clientId)
			urlParams += returnSign(urlParams) + 'clientId=' + filterData.clientId
		if (filterData.startDate)
			urlParams += returnSign(urlParams) + 'startDate=' + filterData.startDate
		if (filterData.endDate)
			urlParams += returnSign(urlParams) + 'endDate=' + filterData.endDate
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if ("/services/web/api/report-expense" + urlParams !== filterUrl && !exportToExcel)
			setFilterDataExist(true)

		if (tableSort) {
			var filterDataCopy = { ...filterData }
			if (filterDataCopy.tableSortKey === tableSort) {
				filterDataCopy.tableSortDirection === ',asc' ? filterDataCopy.tableSortDirection = ',desc' : filterDataCopy.tableSortDirection = ',asc'
			} else {
				filterDataCopy.tableSortDirection = ',asc'
			}
			filterDataCopy.tableSortKey = tableSort

			setFilterData(filterDataCopy)
			urlParams += returnSign(urlParams) + 'sort=' + tableSort + filterDataCopy.tableSortDirection
		}

		filterUrl = filterUrl + urlParams

		if (exportToExcel) {
			filterUrl = "/services/web/api/report-expense-excel" + urlParams + returnSign(filterUrl)

			var excelHeaders = [[t('POS'), t('CASHBOX'), t('CASHIER'), t('EXPENSE_TYPE'),
			t('NOTE'), t('EXPENSE_AMOUNT'), t('DATE')]]
			const response = await GET(filterUrl)
			for (let i = 0; i < response.length; i++) {
				var excelItems = []
				excelItems.push(response[i].posName)
				excelItems.push(response[i].cashboxName)
				excelItems.push(response[i].cashierName)
				excelItems.push(response[i].paymentPurposeName)
				excelItems.push(response[i].note)
				excelItems.push(response[i].amountOut)
				excelItems.push(formatDate(response[i].createdDate))
				excelHeaders.push(excelItems)
			}
			const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
			XLSX.writeFile(wb, t('EXPENSE_REPORT') + ".xlsx");
		} else {
			const response = await PGET(filterUrl, {}, { loader: true })
			if (response) {
				setData(response.data)
				setPagination({ ...pagination, 'url': filterUrl, 'response': response })
			}
			filterUrl = "/services/web/api/report-expense-total/" + urlParams
			const temporaryTotalAmount = await GET(filterUrl)
			setTotalAmont(temporaryTotalAmount)
		}

		setDropdownIsOpen(false)
	}

	function resetFilter() {
		setFilterData({
			startDate: null,
			endDate: null,
			posId: null,
			cashierId: null,
			clientId: null,
			paymentPurposeId: null,
			search: '',
		})
		setFilterDataExist(false)
		getData()
	}

	async function getData() {
		const response = await PGET("/services/web/api/report-expense", {}, { loader: true })
		if (response) {
			setData(response.data)
			setPagination({ ...pagination, 'url': "/services/web/api/report-expense", 'response': response })
		}
		const response2 = await GET("/services/web/api/report-expense-total", {}, { loader: false })
		if (response2) {
			setTotalAmont(response2)
		}
	}

	function checkFilter() {
		if (
			filterData.startDate === null &&
			filterData.endDate === null &&
			filterData.posId === null &&
			filterData.cashierId === null &&
			filterData.clientId === null &&
			filterData.paymentPurposeId === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			response.unshift({ 'id': null, 'name': t('ALL') })
			setPoses(response)
		}
	}

	async function getCashiers() {
		const response = await GET('/services/web/api/cashier-helper')
		if (response) {
			setCashiers(response)
		}
	}

	async function getExpensesHelper() {
		const response = await GET('/services/web/api/expense-helper')
		if (response) {
			response.unshift({ 'id': null, 'name': t('ALL') })
			setPaymentTypes(response)
		}
	}

	async function paginate(newPageData) {
		setData(newPageData)
	}

	useEffect(() => {
		getPoses()
		getCashiers()
		getExpensesHelper()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('EXPENSE_REPORT')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
										{t('FILTER')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('POS')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.posId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('CASHIER')}</label>
												<Select
													options={cashiers}
													value={cashiers.find(option => option.id === filterData.cashierId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'cashierId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('EXPENSE_TYPE')}</label>
												<Select
													options={paymentTypes}
													value={paymentTypes.find(option => option.id === filterData.paymentPurposeId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'paymentPurposeId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('PERIOD')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.startDate ? new Date(filterData.startDate) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'startDate': formatDateBackend(date) })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.endDate ? new Date(filterData.endDate) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'endDate': formatDateBackend(date) })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('APPLY')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<button className="btn btn-outline-primary btn-wide" onClick={() => searchWithFilter(true)}>EXCEL</button>
							</div>
						</div>
						<div className="d-flex">
							<div className="vertical-center me-2">
								<h4>
									<b>{t('TOTAL_AMOUNT')}</b>:
								</h4>
							</div>
							<div className="vertical-center">
								{totalAmount?.map((item, index) => (
									<h5 className="text-end" key={index}>
										<span className="me-1">{formatMoney(item.totalAmount)}</span>
										<b>{item.currencyName}</b>
									</h5>
								))
								}
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{(filterData.startDate || filterData.endDate) &&
									<span className="me-2">
										<b>{t('PERIOD')}: </b>
										{filterData.startDate &&
											<span>{formatDate(filterData.startDate)}</span>
										}
										{(filterData.startDate && filterData.endDate) &&
											<span>|</span>
										}
										{filterData.endDate &&
											<span>{formatDate(filterData.endDate)}</span>
										}
									</span>
								}
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.cashierId &&
									<span className="me-2">
										<b>{t('CASHIER')}: </b> {findFromArrayById(cashiers, filterData.cashierId)}
									</span>
								}
								{filterData.paymentPurposeId &&
									<span className="me-2">
										<b>{t('TYPE')}: </b> {findFromArrayById(paymentTypes, filterData.paymentPurposeId)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th className="th-sortable cursor" onClick={() => searchWithFilter(false, 'posName')}>
										{t('POS')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'posName') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'posName') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable cursor" onClick={() => searchWithFilter(false, 'cashboxName')}>
										{t('CASHBOX')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'cashboxName') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'cashboxName') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable cursor text-center" onClick={() => searchWithFilter(false, 'cashierName')}>
										{t('CASHIER')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'cashierName') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'cashierName') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable cursor text-center" onClick={() => searchWithFilter(false, 'paymentPurposeName')}>
										{t('EXPENSE_TYPE')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'paymentPurposeName') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'paymentPurposeName') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable cursor text-center" onClick={() => searchWithFilter(false, 'note')}>
										{t('NOTE')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'note') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'note') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable cursor text-center" onClick={() => searchWithFilter(false, 'amountOut')}>
										{t('EXPENSE_AMOUNT')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'amountOut') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'amountOut') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable cursor text-center" onClick={() => searchWithFilter(false, 'createdDate')}>
										{t('DATE')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'createdDate') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'createdDate') && 'sort-active')}></i>
										</div>
									</th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr key={index}>
										<td>{item.rowNum}. {item.posName}</td>
										<td>{item.cashboxName}</td>
										<td className="text-center">{item.cashierName}</td>
										<td className="text-center">{item.paymentPurposeName}</td>
										<td className="text-center">{item.note}</td>
										<td className="text-center">{formatMoney(item.amountOut)} {item.currencyName}</td>
										<td className="text-center">{formatDateWithTime(item.createdDate)}</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>
		</>
	)
}

export default Index
